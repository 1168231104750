<template>
  <div>
    <br />
    <!--   <h1>
      To-Do List
      <div @click="openModal" class="pointer add-task-button">+</div>
    </h1>
 -->
    <!-- Conteneur des filtres avec toggle -->
    <div v-if="!hideInCalendar" class="all-filters">
      <!-- Filtre de recherche -->
      <div class="filter-input-container">
        <input
          type="text"
          v-model="state.filters.searchTerm"
          class="filter-input"
          placeholder="Rechercher dans les titres et les descriptions"
        />
      </div>

      <!-- Filtre de département -->

      <!-- Filtre de Person From -->
      <div class="filter-select-container">
        <label for="personFromFilter">Person From</label>
        <select id="personFromFilter" v-model="state.filters.personFrom">
          <option value="">Toutes les personnes</option>
          <option
            v-for="(count, person) in getPersonFromCounts()"
            :key="person"
            :value="person"
          >
            {{ person }} ({{ count }})
          </option>
        </select>
      </div>

      <!-- Filtre de personne "To" -->
      <div class="filter-select-container">
        <label for="personToFilter">Personne To</label>
        <select id="personToFilter" v-model="state.filters.personTo">
          <option value="">Toutes les personnes</option>
          <option
            v-for="(count, person) in getPersonToCounts()"
            :key="person"
            :value="person"
          >
            {{ person }} ({{ count }})
          </option>
        </select>
      </div>

      <!-- Filtre de priorité haute -->
      <div class="">
        <input
          type="checkbox"
          id="highPriority"
          v-model="state.filters.highPriorityOnly"
        />
        <label for="highPriority"
          >Afficher uniquement les tâches de haute priorité</label
        >
      </div>
    </div>
    <div v-for="(tasks, projectName) in filteredTasks" :key="projectName">
      <h2 v-if="!hideInCalendar" class="kanban-project-title">
        <!--  {{ projectName }} :  -->Ajouter une tâche
        <div @click="openModal(projectName)" class="pointer add-task-button">
          +
        </div>
      </h2>

      <section class="kanban-board" :class="`columns-${columns.length}`">
        <div
          v-for="(column, index) in columns"
          :key="index"
          class="kanban-column"
          :class="`column-${index + 1}`"
        >
          <h3 class="kanban-column-title">{{ column.title }}</h3>
          <span
            v-if="column.showInvisibleButton"
            @click="toggleShowInvisible"
            class="show-invisible-button"
          >
            {{ invisibleButtonText }}
          </span>

          <VueDraggableNext
            v-model="column.tasks"
            group="tasks"
            class="draggable-list"
            handle=".drag-handle"
            @end="onEnd"
          >
            <div
              v-for="task in column.tasks"
              :key="task.id"
              :data-id="task.id"
              :style="{
                borderLeft:
                  (task.person_to.includes(task.person_from) &&
                    task.person_to.length > 1) ||
                  task.person_from !== task.person_to[0]
                    ? `10px solid ${getPersonColor(task.person_from)}`
                    : '1px solid var(--main-color10)',
                borderTop: hasUnreadMessages(task) ? '5px solid red' : '', // Vérifie si la tâche a des messages non lus
              }"
              :class="[
                'task-item',
                column.columnClass,
                {
                  expanded: task.expanded,
                },
              ]"
            >
              <!-- Handle de Drag -->

              <!-- Wrapper autour du contenu cliquable -->
              <div class="task-content">
                <div class="person_from">
                  <p @click="toggleTaskExpansion(task)" class="toogle">
                    {{ task.title }}
                  </p>
                  <p class="notification" v-if="task.to_be_notified === 'true'">
                    NEW
                  </p>
                  <p
                    :style="{ color: 'var(--main-color3)' }"
                    v-if="task.priority === 'oui'"
                  >
                    Priorité élevée
                  </p>

                  <div v-if="!hideInCalendar" class="drag-handle">
                    <img src="../../assets/drag-icon.png" alt="Drag" />
                  </div>
                </div>

                <pre
                  class="description"
                  v-if="task.detail && task.detail.length"
                  style="white-space: pre-line; word-wrap: break-word"
                  >{{ task.detail }}</pre
                >

                <div
                  class="alert url"
                  v-if="task.department && task.department.length"
                >
                  <strong>Alert :</strong>
                </div>
                <pre
                  class="description alert"
                  v-if="task.department && task.department.length"
                  style="white-space: pre-line; word-wrap: break-word"
                  >{{ task.department }}</pre
                >

                <div
                  class="url"
                  v-if="task.description && task.description.length"
                >
                  <strong>Tâches à faire :</strong>
                </div>

                <div
                  class="task-description"
                  v-if="task.description && task.description.length"
                >
                  <div
                    v-for="(item, index) in task.description"
                    :key="index"
                    :class="[{ 'checked-item': item.done }, 'checkbox']"
                    @click.stop
                  >
                    <div class="liste">
                      <div class="liste-case">
                        <input
                          type="checkbox"
                          v-model="item.done"
                          @change="updateTaskItemStatus(task, index)"
                        />
                      </div>
                      <div class="liste-label">
                        <pre
                          style="
                            display: inline;
                            white-space: pre-line;
                            word-wrap: break-word;
                          "
                        >
      {{ item.item }}
    </pre
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Display URL as a clickable link if available -->
              <div v-if="task.url && task.url.length">
                <div class="url"><strong>Liens :</strong></div>
                <div v-for="(urlItem, index) in task.url" :key="index">
                  <p class="urls">
                    <a
                      href="javascript:void(0);"
                      @click="handleLinkClick(urlItem.link)"
                    >
                      {{ urlItem.title ? urlItem.title : "Link" + (index + 1) }}
                    </a>
                    <span @click="downloadFile(urlItem.link, urlItem.title)">
                      (Télécharger)
                    </span>
                  </p>
                </div>
              </div>

              <div v-if="task.remarque && task.remarque.length" class="url">
                <strong>Messages :</strong>
              </div>
              <div
                class="chat-container"
                v-if="task.remarque && task.remarque.length"
              >
                <!-- Display remarque sous forme de chat-->
                <div
                  v-for="(remarqueItem, index) in task.remarque"
                  :key="index"
                  class="chat-bubble"
                  :class="{
                    'chat-bubble-right': isCurrentUser(remarqueItem.user),
                    'chat-bubble-left': !isCurrentUser(remarqueItem.user),
                    'chat-bubble-unread':
                      remarqueItem.is_read === false &&
                      !isCurrentUser(remarqueItem.user), // Ajoute la classe si le message n'est pas lu
                  }"
                  @click="markAsRead(remarqueItem)"
                >
                  <div class="chat-bubble-header">
                    <strong>{{ remarqueItem.user }} - </strong>
                    <strong>{{ remarqueItem.id }} - </strong>
                    <span>{{ formatDateTime(remarqueItem.timestamp) }}</span>
                    <template v-if="!isCurrentUser(remarqueItem.user)">
                      <span
                        v-if="remarqueItem.is_read === false"
                        style="color: red"
                      >
                        Non lu
                      </span>
                      <span
                        v-if="remarqueItem.is_read === true"
                        style="color: green"
                      >
                        Lu
                      </span>
                    </template>
                  </div>
                  <p class="chat-bubble-message">
                    {{ remarqueItem.message }}
                  </p>
                </div>
              </div>
              <div class="kanban-last-line">
                <div class="gap">
                  <p>from {{ task.person_from }}</p>
                  <p>to {{ task.person_to.join(", ") }}</p>
                </div>
                <div class="icon">
                  <div @click="deleteTask(task.id)" class="delete-icon">
                    <img src="../../assets/trash-icon.png" alt="Delete" />
                  </div>
                  <div @click="editTaskModal(task)" class="delete-icon">
                    <img src="../../assets/edit-icon.png" alt="Edit" />
                  </div>
                </div>
              </div>
            </div>

            <!-- Empty state placeholder to ensure droppable area -->
            <div
              v-if="filteredTasks.todo && filteredTasks.todo.length === 0"
              class="empty-placeholder"
            >
              Drop tasks here...
            </div>
          </VueDraggableNext>
        </div>
      </section>
    </div>
    <!-- Modal for adding a new task -->
    <transition name="fade" @after-enter="initializeDatePicker">
      <div class="modal" v-if="state.isModalOpen">
        <div class="modal-content">
          <span class="close" @click="closeModal">&times;</span>
          <div class="modal-container">
            <h2>{{ state.isEditing ? "Edit Task" : "Add New Task" }}</h2>
            <div class="chatgpt-container">
              <div class="chatgpt-container-first-column">
                <!--  <div class="chatgpt">
                  <label for="project">Projet</label>
                  <input
                    v-model="state.newTask.project"
                    id="project"
                    type="text"
                  />
                </div> -->
                <div class="chatgpt">
                  <label for="project">Departement</label>

                  <select v-model="state.newTask.project" id="poject">
                    <option value="achat">Achat</option>
                    <option value="compta">Compta</option>
                    <option value="commercial">Commercial</option>
                    <option value="communication">Communication</option>
                    <option value="ecommerce">Ecommerce</option>
                    <option value="email">Email</option>
                    <option value="erp">ERP</option>
                    <option value="logistique">Logistique</option>
                    <option value="packaging">Packaging</option>
                    <option value="production">Production</option>
                    <option value="visuel">Visuels</option>
                    <option value="website">Website</option>
                    <option value="perso">Perso</option>
                    <option value="process">Process</option>
                  </select>
                </div>
                <div class="chatgpt">
                  <label for="title">Title</label>
                  <input v-model="state.newTask.title" id="title" type="text" />
                </div>
                <div class="chatgpt">
                  <label for="title">Detail</label>
                  <textarea
                    v-model="state.newTask.detail"
                    placeholder="Détail"
                    class="description-input"
                    rows="2"
                  ></textarea>
                </div>
                <div class="chatgpt">
                  <label for="title">Alerte</label>
                  <textarea
                    v-model="state.newTask.department"
                    placeholder="Alerte"
                    class="description-input"
                    rows="2"
                  ></textarea>
                </div>
                <div class="chatgpt">
                  <label>Tâche(s) à faire </label>

                  <div class="chatgpt2">
                    <div
                      class="chatgpt3"
                      v-for="(desc, index) in state.newTask.description"
                      :key="index"
                    >
                      <div class="chatgpt4">
                        <textarea
                          v-model="desc.item"
                          placeholder="Item"
                          class="description-input"
                          rows="1"
                        ></textarea>
                      </div>
                      <div class="remove" @click="removeDescriptionItem(index)">
                        Remove
                      </div>
                    </div>

                    <div
                      class="text-align-left underline"
                      @click="addDescriptionItem"
                    >
                      Ajouter une nouvelle tâche
                    </div>
                  </div>
                </div>
                <div class="chatgpt">
                  <label>Lien(s)</label>
                  <div class="chatgpt2">
                    <div
                      class="chatgpt3"
                      v-for="(url, index) in state.newTask.url"
                      :key="index"
                    >
                      <div class="chatgpt4">
                        <textarea
                          v-model="url.title"
                          placeholder="Title"
                          class="description-input"
                          rows="1"
                        ></textarea>
                        <textarea
                          v-model="url.link"
                          placeholder="URL"
                          class="description-input"
                          rows="1"
                        ></textarea>
                      </div>
                      <div @click="removeUrl(index)" class="removePerson">
                        Remove
                      </div>
                    </div>
                    <div class="ajouterURL">
                      <div class="text-align-left underline" @click="addUrl">
                        Ajouter un URL
                      </div>
                      <div class="text-align-left">Ou</div>
                      <!-- Champ d'upload de fichier -->
                      <div class="uploadFile">
                        <div>Uploader un fichier:</div>
                        <div>
                          <input
                            type="file"
                            @change="uploadFile"
                            class="file-input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="chatgpt-container-second-column">
                <div class="chatgpt">
                  <label>Pour</label>
                  <div class="chatgpt6">
                    <div class="chatgpt6">
                      <div
                        class="chatgpt3"
                        v-for="(person, index) in state.newTask.person_to"
                        :key="index"
                      >
                        <select
                          v-model="state.newTask.person_to[index]"
                          id="person_to"
                        >
                          <option :value="mappedUserName">
                            {{ capitalize(mappedUserName) }}
                          </option>
                          <option v-if="mappedUserName !== 'alex'" value="alex">
                            Alex
                          </option>
                          <option
                            v-if="mappedUserName !== 'colas'"
                            value="colas"
                          >
                            Colas
                          </option>
                          <option
                            v-if="mappedUserName !== 'majory'"
                            value="majory"
                          >
                            Majory
                          </option>
                          <option
                            v-if="mappedUserName !== 'mehreen'"
                            value="mehreen"
                          >
                            Mehreen
                          </option>
                          <option
                            v-if="mappedUserName !== 'philippe'"
                            value="philippe"
                          >
                            Philippe
                          </option>
                        </select>

                        <div @click="removePerson(index)" class="removePerson">
                          Remove
                        </div>
                      </div>
                    </div>
                    <div class="text-align-left" @click="addPerson">
                      Ajouter une personne
                    </div>
                  </div>
                </div>

                <div class="chatgpt align-vertical">
                  <label for="sendNotification">Envoyer une notification</label>
                  <input
                    type="checkbox"
                    v-model="state.newTask.send_notification"
                    id="sendNotification"
                  />
                </div>

                <div class="chatgpt5">
                  <div class="chatgpt">
                    <label for="location">Location</label>
                    <select v-model="state.newTask.location" id="location">
                      <option value="bureau">91</option>
                    </select>
                  </div>

                  <div class="chatgpt">
                    <label for="priority">Priority Elevée</label>
                    <select v-model="state.newTask.priority" id="priority">
                      <option value="oui">oui</option>
                      <option value="non">non</option>
                    </select>
                  </div>
                </div>
                <div class="chatgpt">
                  <label for="dueDate">Due Date</label>
                  <input
                    ref="dueDate"
                    v-model="state.newTask.startdate"
                    id="dueDate"
                    type="text"
                  />
                </div>

                <div class="chatgpt">
                  <label for="alwaysVisible">Always Visible</label>
                  <input
                    v-model="state.newTask.alwaysvisible"
                    id="alwaysVisible"
                    type="checkbox"
                    @change="toggleReminderVisibility"
                  />
                </div>

                <div class="chatgpt" v-if="!state.newTask.alwaysvisible">
                  <label for="reminderDaysBefore">
                    Reminder Days Before Due Date
                  </label>
                  <input
                    v-model="state.newTask.reminderdaysbefore"
                    id="reminderDaysBefore"
                    type="number"
                    min="0"
                  />
                </div>

                <!--  /// Ajouter Remarque -->
                <!-- Modal pour ajouter/modifier une tâche -->
                <div
                  v-if="state.newTask.remarque && state.newTask.remarque.length"
                  class="chat-container-modal"
                >
                  <div
                    v-for="(remarqueItem, index) in orderedRemarques"
                    :key="remarqueItem.id || index"
                    class="chat-bubble-modal"
                    :class="{
                      'chat-bubble-right': isCurrentUser(remarqueItem.user),
                    }"
                  >
                    <div class="chat-bubble-header">
                      <strong>{{ remarqueItem.user || "Unknown User" }}</strong>
                      <strong>{{ remarqueItem.id }} - </strong>
                      <span>{{ formatDateTime(remarqueItem.timestamp) }}</span>

                      <template v-if="!isCurrentUser(remarqueItem.user)">
                        <!-- Vérifiez si 'is_read' est false et affichez l'état -->
                        <span
                          v-if="remarqueItem.is_read === false"
                          style="color: red"
                        >
                          Non lu
                        </span>
                        <span
                          v-if="remarqueItem.is_read === true"
                          style="color: green"
                        >
                          Lu
                        </span>
                      </template>

                      <!-- Icône de suppression uniquement pour l'utilisateur actuel -->
                      <template v-if="isCurrentUser(remarqueItem.user)">
                        <img
                          src="../../assets/edit-icon.png"
                          alt="Edit"
                          @click="editRemarque(remarqueItem.id)"
                          class="edit-icon pointer"
                        />
                        <img
                          src="../../assets/trash-icon.png"
                          alt="Delete"
                          @click="removeRemarque(remarqueItem.id)"
                          class="delete-icon pointer"
                        />
                      </template>
                    </div>

                    <!-- Afficher le message de chat uniquement si le message existe -->
                    <p v-if="remarqueItem.message" class="chat-bubble-message">
                      {{ remarqueItem.message }}
                    </p>
                  </div>
                </div>

                <!-- Zone de texte pour taper une nouvelle remarque -->
                <div class="new-remarque-container">
                  <textarea
                    v-model="state.newRemarque"
                    placeholder="Tapez votre message ici..."
                    class="new-remarque-textarea"
                  ></textarea>
                  <div class="send-message" @click="addRemarque">Envoyer</div>
                </div>
              </div>
            </div>
            <!-- Modal Footer -->
            <div class="modal-footer">
              <button @click="saveTask(state.newTask)">Enregistrer</button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Modale avec l'iframe -->
    <transition name="fade">
      <div class="modal" v-if="currentLink">
        <div class="modal-content">
          <button @click="downloadCurrentFile">Télécharger</button>
          <span class="close" @click="closeModalIframe">&times;</span>
          <div class="modal-container">
            <iframe
              v-if="isIframeContent"
              :src="currentLink"
              @load="onIframeLoad"
              frameborder="0"
            ></iframe>
            <img
              v-else
              :src="currentLink"
              @load="onImageLoad"
              class="image-viewer"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>

  <!-- Indicateur de chargement -->
  <div class="loading" v-if="isLoading">
    <div class="spinner"></div>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
import { useStore } from "../../useStore"; // Adjust the path if necessary
import {
  computed,
  reactive,
  nextTick,
  watch,
  onMounted,
  onBeforeUnmount,
} from "vue";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

export default {
  data() {
    return {
      personColorMapping: {
        alex: "var(--main-color)",
        colas: "var(--main-color8)",
        majory: "var(--main-color2)",
        mehreen: "var(--main-color9)",
        philippe: " var(--main-color10)",
      },
      isFiltersVisible: false,
      isLoading: false,
      tasks: {
        todo: [],
        inProgress: [],
        done: [],
      },
      userMessageSettingsSubscription: null,
      currentLink: null,
      isIframeContent: true,
    };
  },
  components: {
    VueDraggableNext,
  },
  props: {
    projectName: {
      type: String,
      required: true,
    },
    customFilter: {
      type: Function,
      default: null,
    },
    columnsToShow: {
      type: Array,
      default: () => ["To Do", "In Progress", "Done"],
    },
    hideInCalendar: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const userEmail = computed(() => store.userEmail);

    const personMapping = {
      "alexandra411@gmail.com": "alex",
      "orders@unenuitnomade.com": "colas",
      "cubizollesmajory@gmail.com": "majory",
      "sigmundphil@gmail.com": "philippe",
      "mehreen@unenuitnomade.com": "mehreen",
      "roxane.lagache@gmail.com": "roxane",
    };

    const mappedUserName = computed(() => personMapping[userEmail.value]);
    console.log("mappedUserNameinsetup:", mappedUserName.value);

    const state = reactive({
      tasks: [],

      projectName: props.projectName,
      newTask: {
        title: "",
        description: [],
        person_from: mappedUserName.value,
        person_to: [mappedUserName.value],
        department: "",
        url: [],
        startdate: null,
        enddate: null,
        status: "to-do",
        priority: "non",
        alwaysvisible: true,
        reminderdaysbefore: 2,
        remarque: [],
        detail: "",
        expanded: true,
        to_be_notified: "false",
        send_notification: false,
        project: "",
      },
      filters: {
        highPriorityOnly: false,
        department: "",
        searchTerm: "",
        personFrom: "",
        personTo: "",
      },
      editingRemarqueIndex: null,
      editingTimestamp: null,
      newRemarque: "",
    });

    const resetFilters = () => {
      state.filters.highPriorityOnly = false;
      state.filters.department = "";
      state.filters.searchTerm = "";
      state.filters.personFrom = "";
      state.filters.personTo = "";
    };

    // Watcher pour surveiller les changements de projectName
    watch(
      () => props.projectName,
      (newProjectName) => {
        state.projectName = newProjectName;
        resetFilters(); // Réinitialiser les filtres lorsque le projet change
      },
    );

    const fetchTasks = async () => {
      try {
        state.isLoading = true;

        // Include the user_id in the API request
        const response = await fetch(
          `${process.env.VUE_APP_BASE_URL_3000}/cominterne/get_active_events?user_id=${mappedUserName.value}`,
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        state.tasks = data.map((task) => ({
          ...task,
          remarque: task.remarque || [],
          expanded: task.expanded === "true",
          to_be_notified: task.to_be_notified,
          description:
            task.description?.map((desc) => ({
              ...desc,
              done: desc.done || false,
            })) || [],
        }));
      } catch (error) {
        console.error("Error fetching tasks:", error);
        state.tasks = [];
      } finally {
        state.isLoading = false;
      }
    };

    fetchTasks();

    const handleUserMessageSettingsUpdate = (payload) => {
      console.log(
        "Child Component Received user-message-settings-update event:",
        payload,
      );
      // Trigger fetchTasks to update the tasks
      fetchTasks();
    };

    const handleUserTaskSettingsUpdate = (payload) => {
      console.log(
        "TodoList Component Received user-task-settings-update event:",
        payload,
      );
      // Trigger fetchTasks to update the tasks
      fetchTasks();
    };

    onMounted(() => {
      // Commencer à écouter l'événement
      store.eventEmitter.on(
        "user-message-settings-update",
        handleUserMessageSettingsUpdate,
      );

      store.eventEmitter.on(
        "user-task-settings-update",
        handleUserTaskSettingsUpdate,
      );

      // Vous pouvez également initialiser fetchTasks ici si nécessaire
      fetchTasks();
    });

    onBeforeUnmount(() => {
      // Arrêter d'écouter l'événement
      store.eventEmitter.off(
        "user-message-settings-update",
        handleUserMessageSettingsUpdate,
      );
      store.eventEmitter.off(
        "user-task-settings-update",
        handleUserTaskSettingsUpdate,
      );
    });

    // Optionally, watch the reactive property
    watch(
      () => store.unreadMessagesUpdatedAt,
      (newVal) => {
        console.log(
          "Child Component detected unreadMessagesUpdatedAt change:",
          newVal,
        );
        fetchTasks();
      },
    );

    const filterTasksByUser = (tasks) => {
      return tasks.filter((task) => {
        return (
          task.person_from === mappedUserName.value ||
          (Array.isArray(task.person_to) &&
            task.person_to.includes(mappedUserName.value))
        );
      });
    };

    const filteredTasks = computed(() => {
      const tasksByProject = {};

      // First, get tasks filtered by user
      let tasksToFilter = filterTasksByUser(state.tasks);

      // Apply customFilter if provided
      if (props.customFilter) {
        tasksToFilter = tasksToFilter.filter(props.customFilter);
      }

      tasksToFilter.forEach((task) => {
        const isVisible =
          task.alwaysvisible || calculateVisibility(task) || task.showManually;

        const matchesPriority = state.filters.highPriorityOnly
          ? task.priority === "oui"
          : true;
        const matchesDepartment = state.filters.department
          ? task.department === state.filters.department
          : true;
        const matchesPersonFrom = state.filters.personFrom
          ? task.person_from === state.filters.personFrom
          : true;
        const matchesPersonTo = state.filters.personTo
          ? Array.isArray(task.person_to) &&
            task.person_to.includes(state.filters.personTo)
          : true;
        const matchesSearchTerm = state.filters.searchTerm
          ? (task.title &&
              task.title
                .toLowerCase()
                .includes(state.filters.searchTerm.toLowerCase())) ||
            (task.description &&
              task.description.some((desc) =>
                desc.item
                  .toLowerCase()
                  .includes(state.filters.searchTerm.toLowerCase()),
              ))
          : true;

        if (
          task.project === props.projectName &&
          isVisible &&
          matchesPriority &&
          matchesDepartment &&
          matchesPersonFrom &&
          matchesPersonTo &&
          matchesSearchTerm
        ) {
          if (!tasksByProject[task.project]) {
            tasksByProject[task.project] = {
              todo: [],
              inProgress: [],
              done: [],
            };
          }

          if (task.status === "to-do") {
            tasksByProject[task.project].todo.push(task);
          } else if (task.status === "in-progress") {
            tasksByProject[task.project].inProgress.push(task);
          } else if (task.status === "done") {
            tasksByProject[task.project].done.push(task);
          }
        }
      });

      return tasksByProject;
    });

    const calculateVisibility = (task) => {
      if (!task.startdate || task.alwaysvisible) {
        return true;
      }

      const startDate = new Date(task.startdate);
      const today = new Date();

      const visibleDate = new Date(startDate);
      visibleDate.setDate(visibleDate.getDate() - task.reminderdaysbefore);

      return today >= visibleDate;
    };

    const getDepartmentCounts = () => {
      /*     console.log(
        "mappedUserName in getDepartmentCounts:",
        mappedUserName.value,
      );
      console.log(
        "Projet sélectionné dans getDepartmentCounts:",
        state.projectName,
      );
 */
      const counts = {};

      state.tasks
        .filter((task) => {
          const isVisible =
            task.alwaysvisible ||
            calculateVisibility(task) ||
            task.showManually;

          const isUserInvolved =
            task.person_from === mappedUserName.value ||
            (Array.isArray(task.person_to) &&
              task.person_to.includes(mappedUserName.value));

          return (
            isVisible &&
            isUserInvolved &&
            task.status !== "done" &&
            task.project === state.projectName // Utilisation du projet depuis l'état
          );
        })
        .forEach((task) => {
          if (task.department) {
            if (!counts[task.department]) {
              counts[task.department] = 0;
            }
            counts[task.department]++;
          }
        });

      return counts;
    };

    const tasks = computed(() => {
      const projectTasks = filteredTasks.value[props.projectName] || {
        todo: [],
        inProgress: [],
        done: [],
      };
      return projectTasks;
    });

    /*   const columns = computed(() => [
      {
        title: "To Do",
        tasks: tasks.value.todo,
        columnClass: "",
        showInvisibleButton: true,
      },
      {
        title: "In Progress",
        tasks: tasks.value.inProgress,
        columnClass: "",
        showInvisibleButton: false,
      },
      {
        title: "Done",
        tasks: tasks.value.done,
        columnClass: "done",
        showInvisibleButton: false,
      },
    ]); */

    const columns = computed(() => {
      const allColumns = [
        {
          title: "To Do",
          tasks: tasks.value.todo,
          columnClass: "",
          showInvisibleButton: true,
        },
        {
          title: "In Progress",
          tasks: tasks.value.inProgress,
          columnClass: "",
          showInvisibleButton: false,
        },
        {
          title: "Done",
          tasks: tasks.value.done,
          columnClass: "done",
          showInvisibleButton: false,
        },
      ];

      // Filter columns based on props.columnsToShow
      return allColumns.filter((column) =>
        props.columnsToShow.includes(column.title),
      );
    });

    const tasksWithUnreadComments = computed(() => {
      return state.tasks.filter((task) => {
        return task.remarque.some(
          (remarque) =>
            !remarque.is_read && remarque.user !== mappedUserName.value,
        );
      });
    });

    return {
      state,
      filteredTasks,
      getDepartmentCounts: () => getDepartmentCounts(mappedUserName.value),
      mappedUserName: mappedUserName.value,
      columns,
      tasksWithUnreadComments,
    };
  },

  computed: {
    orderedRemarques() {
      return this.state.newTask.remarque.slice().reverse();
    },
    invisibleButtonText() {
      const hasInvisibleTasks = this.state.tasks.some(
        (task) =>
          !task.alwaysvisible &&
          !this.calculateVisibility(task) &&
          !task.showManually,
      );
      return hasInvisibleTasks
        ? "Show Invisible Tasks"
        : "Hide Invisible Tasks";
    },
    hasUnreadMessagesInProject() {
      return this.filteredTasks.some((task) =>
        task.remarque.some((remarque) => !remarque.is_read),
      );
    },
  },

  mounted() {
    this.fetchTasks();
    this.initializeDatePicker(); // Fetch tasks when the component is mounted
    this.$emit("load-tasks", this.projectName);
  },

  methods: {
    handleMove() {
      // Prefixer avec un underscore
      const selection = window.getSelection();
      if (selection && selection.toString().length > 0) {
        return false; // Empêche le drag si du texte est sélectionné
      }
      return true; // Autorise le drag sinon
    },
    filterTasksByUser(tasks) {
      // Existing filtering logic
      let filteredTasks = tasks.filter((task) => {
        return (
          task.person_from === this.mappedUserName ||
          (Array.isArray(task.person_to) &&
            task.person_to.includes(this.mappedUserName))
        );
      });

      // Apply the custom filter if provided
      if (this.customFilter) {
        filteredTasks = filteredTasks.filter(this.customFilter);
      }

      return filteredTasks;
    },
    hasUnreadMessages(task) {
      return (
        task.remarque &&
        task.remarque.some(
          (remarqueItem) =>
            !remarqueItem.is_read && !this.isCurrentUser(remarqueItem.user),
        )
      );
    },

    async markAsRead(remarqueItem) {
      try {
        if (remarqueItem.is_read) return;

        const response = await fetch(
          `${process.env.VUE_APP_BASE_URL_3000}/cominterne/mark_message_as_read`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              message_id: remarqueItem.id,
              user: this.mappedUserName,
            }),
          },
        );

        if (!response.ok) {
          throw new Error("Failed to update the message status");
        }

        const result = await response.json();
        if (result.success) {
          // Mettre à jour localement l'état de lecture
          remarqueItem.is_read = true;

          // Émettre un événement vers le parent
          this.$emit("message-read", this.state.projectName);

          // Optionnel : Rafraîchir les données ou mettre à jour l'interface utilisateur
          await this.fetchTasks();
        }
      } catch (error) {
        console.error("Error marking message as read:", error);
      }
    },

    capitalize(name) {
      if (!name) return "";
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    async toggleTaskExpansion(task) {
      task.expanded = !task.expanded; // Toggle the expanded state

      try {
        await this.editTask(task); // Save the updated task to the database
      } catch (error) {
        console.error("Error saving task expansion state:", error);
      }
    },
    isURL(str) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // Protocole
          "((([a-zA-Z0-9\\-\\_]+\\.)+[a-zA-Z]{2,})|" + // Nom de domaine
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // Ou adresse IP (IPv4)
          "(\\:\\d+)?(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*" + // Port et chemin d'accès
          "(\\?[;&a-zA-Z0-9%_.~+=-]*)?" + // Paramètres de requête
          "(\\#[-a-zA-Z0-9_]*)?$", // Fragment d'ancre
      );
      return pattern.test(str);
    },
    initializeDatePicker() {
      nextTick(() => {
        if (this.$refs.dueDate) {
          flatpickr(this.$refs.dueDate, {
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            defaultHour: 2, // Set default time to midnight
            defaultMinute: 0, // Set default minute to 0
            onChange: this.updateDate,
          });
        }
      });
    },
    updateDate(selectedDates) {
      this.state.newTask.startdate = selectedDates[0].toISOString();
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      // Format the date part
      const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
      const datePart = date.toLocaleDateString("fr-FR", dateOptions);

      // Get the UTC hours and minutes to avoid DST issues
      const utcHours = date.getUTCHours();
      const utcMinutes = date.getUTCMinutes();

      // Check if UTC time is not exactly 00:00
      if (utcHours !== 0 || utcMinutes !== 0) {
        // Format the time part without seconds
        const timeOptions = { hour: "2-digit", minute: "2-digit" };
        const timePart = date.toLocaleTimeString("fr-FR", timeOptions);
        return `${datePart} ${timePart}`;
      }

      // Return only the date if UTC time is midnight
      return datePart;
    },
    getPersonColor(person) {
      return this.personColorMapping[person] || "white";
    },
    computeTaskStyle(task) {
      if (
        task.person_to.includes(this.state.mappedUserName) &&
        task.person_from !== this.state.mappedUserName
      ) {
        const borderColor = this.getPersonColor(task.person_from);
        return {
          borderLeft: `10px solid ${borderColor}`,
        };
      } else if (
        task.person_from === this.state.mappedUserName &&
        !task.person_to.includes(this.state.mappedUserName)
      ) {
        const borderColor = this.getPersonColor(task.person_from);
        return {
          borderLeft: `10px solid ${borderColor}`,
        };
      }

      return {};
    },
    async fetchTasks() {
      try {
        this.state.isLoading = true;

        const store = useStore();
        const userEmail = store.userEmail;

        const personMapping = {
          "alexandra411@gmail.com": "alex",
          "orders@unenuitnomade.com": "colas",
          "cubizollesmajory@gmail.com": "majory",
          "sigmundphil@gmail.com": "philippe",
          "mehreen@unenuitnomade.com": "mehreen",
          "roxane.lagache@gmail.com": "roxane",
        };

        const mappedUserName = personMapping[userEmail];

        // Log the mappedUserName before making the fetch request
        console.log("Mapped User Name dans fetch new:", mappedUserName);

        if (!mappedUserName) {
          throw new Error("User mapping not found");
        }

        // Utilisez mappedUserName pour passer le user_id
        const response = await fetch(
          `${process.env.VUE_APP_BASE_URL_3000}/cominterne/get_active_events?user_id=${mappedUserName}`,
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // Log the fetched data
        /*      console.log("Fetched data:", data); */

        if (data && Array.isArray(data)) {
          this.state.tasks = data.map((task) => {
            // Handle expanded state correctly
            const isExpanded = task.expanded === "true"; // Compare with the string "true"
            return {
              ...task,
              expanded: isExpanded, // Store the boolean value directly
              description:
                task.description?.map((desc) => ({
                  ...desc,
                  done: desc.done || false,
                })) || [], // Handle descriptions
            };
          });
          // Wait for the DOM update cycle to complete
          await this.$nextTick();

          // Log the computed properties after the state update
        } else {
          console.error("Data is not an array or is null:", data);
          this.state.tasks = []; // Ensure tasks array is cleared
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
        this.state.tasks = []; // Clear the tasks to ensure UI doesn't break
      } finally {
        this.state.isLoading = false;
      }
    },
    addPerson() {
      if (!Array.isArray(this.state.newTask.person_to)) {
        this.state.newTask.person_to = [];
      }
      this.state.newTask.person_to.push(this.state.mappedUserName);
      console.log("Person To after adding:", this.state.newTask.person_to);
    },

    removePerson(index) {
      this.state.newTask.person_to.splice(index, 1);
      console.log("Person To after removing:", this.state.newTask.person_to);
    },
    updateTaskItemStatus(task, index) {
      const currentState = task.description[index].done;
      task.description[index].done = currentState;

      const hasInProgress = task.description.some((item) => item.done);
      const allDone = task.description.every((item) => item.done);

      if (allDone) {
        task.status = "done";
        task.completedat = new Date().toISOString();
      } else if (hasInProgress) {
        task.status = "in-progress";
        task.completedat = null;
      } else {
        task.status = "to-do";
        task.completedat = null;
      }

      // Supprimer les propriétés inutiles avant d'envoyer la tâche au serveur
      delete task.dueDate;
      delete task.showManually;

      console.log("JSON sent to editTask:", JSON.stringify(task, null, 2));
      this.editTask(task);

      // Vérifier si le projet est "production" et si l'élément contient un BdcNumber
      const descriptionItem = task.description[index];
      if (task.project === "production" && descriptionItem.BdcNumber) {
        const BdcNumber = descriptionItem.BdcNumber; // Utiliser BdcNumber
        const BdcStatus = descriptionItem.done ? "TRUE" : "FALSE";

        // Appeler la fonction pour mettre à jour BdcStatus dans ASSEMBLIES
        this.updateBdcStatus(BdcNumber, BdcStatus);
      } else if (
        task.project === "production" &&
        descriptionItem.AssemblyNumber
      ) {
        const AssemblyNumber = descriptionItem.AssemblyNumber; // Utiliser BdcNumber
        const BLStatus = descriptionItem.done ? "TRUE" : "FALSE";

        // Appeler la fonction pour mettre à jour BdcStatus dans ASSEMBLIES
        this.updateBLStatus(AssemblyNumber, BLStatus);
      }

      // Ajouter une classe de surlignage temporaire
      this.$nextTick(() => {
        const taskElement = document.querySelector(`[data-id="${task.id}"]`);
        if (taskElement) {
          taskElement.classList.add("highlighted");
          setTimeout(() => {
            taskElement.classList.remove("highlighted");
          }, 2000); // Supprime la classe après 2 secondes
        }
      });
    },
    async updateBdcStatus(BdcNumber, BdcStatus) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_URL_3000}/inventory/update_bdc_status`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ BdcNumber, BdcStatus }), // Envoyer BdcNumber
          },
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            `Erreur lors de la mise à jour du BdcStatus: ${errorData.error || "Erreur inconnue"}`,
          );
        }

        const result = await response.json();
        console.log("BdcStatus mis à jour avec succès:", result);
      } catch (error) {
        console.error("Erreur lors de la mise à jour du BdcStatus:", error);
        alert(`Erreur lors de la mise à jour du BdcStatus: ${error.message}`);
      }
    },
    async updateBLStatus(AssemblyNumber, BLStatus) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_URL_3000}/inventory/update_bl_status`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ AssemblyNumber, BLStatus }), // Envoyer BdcNumber
          },
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            `Erreur lors de la mise à jour du BlStatus: ${errorData.error || "Erreur inconnue"}`,
          );
        }

        const result = await response.json();
        console.log("BdcStatus mis à jour avec succès:", result);
      } catch (error) {
        console.error("Erreur lors de la mise à jour du BdcStatus:", error);
        alert(`Erreur lors de la mise à jour du BdcStatus: ${error.message}`);
      }
    },
    async editTask(task) {
      try {
        // Ajoute le nom de l'utilisateur actuel aux données de la tâche
        const taskToUpdate = {
          ...task,
          user: this.mappedUserName, // Utilise le nom d'utilisateur connecté
          // Assurez-vous que to_be_notified est inclus et reste un tableau
          to_be_notified: task.to_be_notified || [],
        };

        // Log the task object before sending the request
        console.log(
          "JSON to be sent in editTask:",
          JSON.stringify(taskToUpdate, null, 2),
        );

        const response = await fetch(
          `${process.env.VUE_APP_BASE_URL_3000}/cominterne/update_event`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(taskToUpdate),
          },
        );

        if (response.ok) {
          await this.fetchTasks(); // Refresh tasks
        } else {
          const errorData = await response.json();
          console.error(
            "Error updating task:",
            errorData.error || "Unknown error",
          );
        }
      } catch (error) {
        console.error("Error updating task:", error);
      }
    },

    async editTaskModal(task) {
      // Populate the newTask with the existing task data
      this.state.newTask = {
        ...task,
        remarque: task.remarque || [], // Assure que 'remarque' est un tableau
      };

      if (task.startdate) {
        // Convert the UTC startdate to local Paris time
        const startDateTime = new Date(task.startdate)
          .toLocaleString("sv-SE", { timeZone: "Europe/Paris" })
          .slice(0, 16);
        this.state.newTask.startdate = startDateTime;

        // If enddate exists and is different from startdate, handle it separately
        if (task.enddate && task.enddate !== task.startdate) {
          const endDateTime = new Date(task.enddate)
            .toLocaleString("sv-SE", { timeZone: "Europe/Paris" })
            .slice(0, 16);
          this.state.newTask.enddate = endDateTime;
        } else {
          // If enddate is not different, set it the same as startdate
          this.state.newTask.enddate = startDateTime;
        }

        console.log(
          "Formatted Local Date and Time for startdate and enddate:",
          startDateTime,
        );
      } else {
        console.log("No startdate found on the task:", task);
        this.state.newTask.startdate = null;
        this.state.newTask.enddate = null;
      }

      // Open the modal for editing
      this.state.isEditing = true;
      this.state.isModalOpen = true;

      console.log("Modal State isEditing:", this.state.isEditing);
      console.log("Modal State isModalOpen:", this.state.isModalOpen);
    },
    async deleteTask(taskId) {
      this.state.isLoading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_URL_3000}/cominterne/delete_event`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: taskId }),
          },
        );

        if (!response.ok) {
          const errorData = await response.json();
          console.error(
            "Error deleting task:",
            errorData.error || "Unknown error",
          );
          return;
        }

        console.log(`Task with ID ${taskId} deleted.`);
        await this.fetchTasks();
      } catch (error) {
        console.error("Error deleting task:", error);
      } finally {
        this.state.isLoading = false;
      }
    },

    async onEnd(event) {
      const movedTaskId = event.item.getAttribute("data-id");

      const movedTask = this.state.tasks.find(
        (task) => task.id === parseInt(movedTaskId, 10),
      );

      if (!movedTask) {
        console.error(`Task with ID ${movedTaskId} not found.`);
        return;
      }

      // Détermine la nouvelle colonne
      const newColumn = event.to
        .closest(".kanban-column")
        .querySelector(".kanban-column-title").innerText;

      // Mise à jour du statut selon la colonne cible
      if (newColumn === "To Do") {
        movedTask.status = "to-do";
        movedTask.completedat = null;
      } else if (newColumn === "In Progress") {
        movedTask.status = "in-progress";
        movedTask.completedat = null;
      } else if (newColumn === "Done") {
        movedTask.status = "done";
        movedTask.completedat = new Date().toISOString();
      }
      if (newColumn === "Done") {
        movedTask.status = "done";
        movedTask.completedat = new Date().toISOString();

        // Marquer tous les messages de la tâche comme lus pour tous les utilisateurs
        if (movedTask.remarque && movedTask.remarque.length > 0) {
          await this.markAllMessagesAsReadForAllUsers(movedTask);
        }
      }
      // Supprimer les propriétés inutiles
      delete movedTask.dueDate;
      delete movedTask.showManually;

      // Sauvegarde la tâche mise à jour
      await this.editTask(movedTask);
    },
    async markAllMessagesAsReadForAllUsers(task) {
      try {
        const messageIds = task.remarque.map((remarqueItem) => remarqueItem.id);
        const uniqueMessageIds = [...new Set(messageIds)];

        // Envoyer tous les message_ids en une seule requête
        const response = await fetch(
          `${process.env.VUE_APP_BASE_URL_3000}/cominterne/mark_messages_as_read_for_all`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              message_ids: uniqueMessageIds,
            }),
          },
        );

        if (!response.ok) {
          throw new Error(
            "Échec de la mise à jour du statut des messages pour tous les utilisateurs",
          );
        }

        // Mettre à jour localement l'état de lecture
        task.remarque.forEach((remarqueItem) => {
          remarqueItem.is_read = true;
        });

        // Rafraîchir les tâches
        await this.fetchTasks();
      } catch (error) {
        console.error(
          "Erreur lors du marquage des messages comme lus pour tous :",
          error,
        );
      }
    },

    resetTaskData(projectName) {
      const mappedUserName = this.mappedUserName;
      this.state.newTask = {
        title: "",
        description: [],
        person_from: mappedUserName,
        person_to: [mappedUserName],
        department: "",
        url: "",
        startdate: null,
        enddate: null,
        location: "",
        status: "to-do",
        priority: "non",
        alwaysvisible: true,
        reminderdaysbefore: 2,
        detail: "",
        expanded: true,
        to_be_notified: "",
        project: projectName,
        send_notification: false, // Remplir le champ project avec la valeur passée
        remarque: [],
      };
      console.log("Reset New Task:", this.state.newTask);
    },

    openModal(projectName) {
      console.log(
        "Opening Modal. Current New Task before reset:",
        this.state.newTask,
      );
      this.state.isModalOpen = true;
      if (!this.state.isEditing) {
        this.resetTaskData(projectName); // Passer projectName ici
      }
      console.log("New Task after reset:", this.state.newTask);
    },

    closeModal() {
      this.state.isModalOpen = false;
      this.state.isEditing = false; // Reset editing state when closing
      this.resetTaskData(); // Ensure form is reset on close
    },

    addDescriptionItem() {
      this.state.newTask.description.push({ item: "", done: false });
    },

    removeDescriptionItem(index) {
      this.state.newTask.description.splice(index, 1);
    },

    async saveTask() {
      this.state.isLoading = true;

      try {
        // Check if "Always Visible" is unchecked and "Due Date" is not provided
        if (
          !this.state.newTask.alwaysvisible &&
          !this.state.newTask.startdate
        ) {
          alert("Date obligatoire lorsque 'Always Visible' est décoché.");
          this.state.isLoading = false; // Stop loading if validation fails
          return; // Prevent the task from being saved
        }
        // Log person_from to ensure it's being set correctly

        const taskToSave = { ...this.state.newTask };

        // Ensure person_from is correctly set
        if (!taskToSave.person_from) {
          taskToSave.person_from =
            this.state.newTask.person_from || this.state.mappedUserName;
        }

        // If startdate exists, convert it to ISO string with timezone information
        if (taskToSave.startdate) {
          const dateWithTimezone = new Date(
            taskToSave.startdate,
          ).toLocaleString("en-US", {
            timeZone: "Europe/Paris",
            hour12: false,
          });
          taskToSave.startdate = new Date(dateWithTimezone).toISOString();

          // Set enddate to be the same as startdate
          taskToSave.enddate = taskToSave.startdate;
        } else {
          // If startdate is null, ensure enddate is also set to null
          taskToSave.startdate = null;
          taskToSave.enddate = null;
        }

        // Handle notifications
        if (taskToSave.send_notification) {
          // Filtrer les utilisateurs pour exclure le current user
          const usersToNotify = taskToSave.person_to.filter(
            (person) => person !== this.mappedUserName,
          );

          // Ajouter une propriété pour les notifications
          taskToSave.to_be_notified = usersToNotify.map((user) => ({
            user: user,
            to_be_notified: "true",
          }));
        } else {
          taskToSave.to_be_notified = [];
        }

        // Remove any unnecessary properties before sending to server
        delete taskToSave.dueDate;
        delete taskToSave.showManually;
        delete taskToSave.send_notification;

        // Log the JSON that will be sent
        console.log("JSON to be sent:", JSON.stringify(taskToSave, null, 2));

        const endpoint = this.state.isEditing
          ? `${process.env.VUE_APP_BASE_URL_3000}/cominterne/update_event`
          : `${process.env.VUE_APP_BASE_URL_3000}/cominterne/new_event`;

        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(taskToSave),
        });

        if (response.ok) {
          const data = await response.json();

          if (this.state.isEditing) {
            const index = this.state.tasks.findIndex(
              (task) => task.id === this.state.newTask.id,
            );
            if (index !== -1) {
              this.state.tasks.splice(index, 1, { ...data });
            }
          } else {
            this.state.tasks.push({ ...data });
          }

          this.state.isEditing = false;
          this.resetTaskData();
          this.closeModal();
          await this.fetchTasks();
        } else {
          const errorData = await response.json();
          console.error(
            "Error saving task:",
            errorData.error || "Unknown error",
          );
        }
      } catch (error) {
        console.error("Error saving/updating task:", error);
      } finally {
        this.state.isLoading = false;
      }
    },

    calculateVisibility(task) {
      if (!task.startdate || task.alwaysvisible) {
        return true; // Always visible or no startdate
      }

      const startDate = new Date(task.startdate);
      const today = new Date();

      // Calculate the date when the task should become visible
      const visibleDate = new Date(startDate);
      visibleDate.setDate(visibleDate.getDate() - task.reminderdaysbefore);

      return today >= visibleDate;
    },
    showInvisibleTask(task) {
      task.showManually = true;
    },
    toggleShowInvisible() {
      const allShown = this.state.tasks.every(
        (task) =>
          task.showManually ||
          task.alwaysvisible ||
          this.calculateVisibility(task),
      );

      this.state.tasks.forEach((task) => {
        if (!task.alwaysvisible && !this.calculateVisibility(task)) {
          task.showManually = !allShown; // Toggle based on current state
        }
      });
    },
    getDepartmentCount() {
      const mappedUserName = this.mappedUserName; // Accéder à mappedUserName depuis le contexte de l'instance

      /*   console.log("mappedUserName in getDepartmentCounts:", mappedUserName); */
      /*    console.log(
        "Projet sélectionné dans getDepartmentCounts:",
        this.state.projectName,
      );
 */
      const counts = {};

      this.state.tasks
        .filter((task) => {
          const isVisible =
            task.alwaysvisible ||
            this.calculateVisibility(task) ||
            task.showManually;

          const isUserInvolved =
            task.person_from === mappedUserName ||
            (Array.isArray(task.person_to) &&
              task.person_to.includes(mappedUserName));

          return (
            isVisible &&
            isUserInvolved &&
            task.status !== "done" &&
            task.project === this.state.projectName // Utilisation de this.state.projectName
          );
        })
        .forEach((task) => {
          if (task.department) {
            if (!counts[task.department]) {
              counts[task.department] = 0;
            }
            counts[task.department]++;
          }
        });

      return counts;
    },

    getPersonFromCounts() {
      const mappedUserName = this.mappedUserName;
      /*
      console.log("mappedUserName in getPersonFromCounts:", mappedUserName);
      console.log(
        "Projet sélectionné dans getPersonFromCounts:",
        this.state.projectName,
      ); */

      const counts = {};

      this.state.tasks
        .filter((task) => {
          const isVisible =
            task.alwaysvisible ||
            this.calculateVisibility(task) ||
            task.showManually;

          const isUserInvolvedAsTo =
            task.person_from &&
            task.person_from !== mappedUserName &&
            Array.isArray(task.person_to) &&
            task.person_to.includes(mappedUserName);

          return (
            isVisible &&
            isUserInvolvedAsTo &&
            task.status !== "done" &&
            task.project === this.state.projectName
          );
        })
        .forEach((task) => {
          if (task.person_from) {
            if (!counts[task.person_from]) {
              counts[task.person_from] = 0;
            }
            counts[task.person_from]++;
          }
        });

      return counts;
    },

    getPersonToCounts() {
      const mappedUserName = this.mappedUserName;

      /*    console.log("mappedUserName in getPersonToCounts:", mappedUserName);
      console.log(
        "Projet sélectionné dans getPersonToCounts:",
        this.state.projectName,
      ); */

      const counts = {};

      this.state.tasks
        .filter((task) => {
          const isVisible =
            task.alwaysvisible ||
            this.calculateVisibility(task) ||
            task.showManually;

          const isUserInvolvedAsFrom =
            task.person_from === mappedUserName &&
            Array.isArray(task.person_to) &&
            task.person_to.some((person) => person !== mappedUserName);

          return (
            isVisible &&
            isUserInvolvedAsFrom &&
            task.status !== "done" &&
            task.project === this.state.projectName
          );
        })
        .forEach((task) => {
          task.person_to.forEach((person) => {
            if (person !== mappedUserName) {
              if (!counts[person]) {
                counts[person] = 0;
              }
              counts[person]++;
            }
          });
        });

      return counts;
    },

    // Ajout d'une URL
    addUrl() {
      if (!Array.isArray(this.state.newTask.url)) {
        this.state.newTask.url = [];
      }
      this.state.newTask.url.push({ title: "", link: "" });
    },
    // Suppression d'une URL
    removeUrl(index) {
      this.state.newTask.url.splice(index, 1);
    },

    isCurrentUser(user) {
      const mappedUserName = this.mappedUserName;
      /*    console.log(
        "Checking user:",
        user,
        "against mappedUserName:",
        mappedUserName,
      ); */
      return user === mappedUserName;
    },

    formatDateTime(timestamp) {
      const date = new Date(timestamp);
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "2-digit",
      };
      return date.toLocaleDateString("fr-FR", options);
    },
    // Ajout d'une nouvelle remarque ou édition
    // Ajout d'une nouvelle remarque ou édition
    // In your Vue component
    addRemarque() {
      const mappedUserName = this.mappedUserName;

      if (!this.state.newRemarque || this.state.newRemarque.trim() === "") {
        return;
      }

      const timestamp = this.editingTimestamp
        ? this.editingTimestamp
        : new Date().toISOString();

      const newRemarque = {
        id: this.editingRemarqueId || undefined, // Use existing id or undefined
        user: mappedUserName,
        timestamp: timestamp,
        message: this.state.newRemarque.trim(),
      };

      if (this.editingRemarqueIndex !== null) {
        // Update existing remark
        this.state.newTask.remarque.splice(
          this.editingRemarqueIndex,
          1,
          newRemarque,
        );
      } else {
        // Add new remark
        this.state.newTask.remarque.push(newRemarque);
      }

      // Reset editing state
      this.state.newRemarque = "";
      this.editingRemarqueIndex = null;
      this.editingRemarqueId = null;
      this.editingTimestamp = null;
    },

    // Supprime une remarque
    // Supprime une remarque
    removeRemarque(id) {
      const index = this.state.newTask.remarque.findIndex(
        (remarque) => remarque.id === id,
      );
      if (index !== -1) {
        this.state.newTask.remarque.splice(index, 1);
        console.log(`Message with id ${id} removed.`);
      }
    },

    // Ouvre le mode d'édition pour un message
    editRemarque(id) {
      const index = this.state.newTask.remarque.findIndex(
        (remarque) => remarque.id === id,
      );
      if (index !== -1) {
        this.editingRemarqueIndex = index; // Stocke l'index de la remarque à modifier
        this.state.newRemarque = this.state.newTask.remarque[index].message; // Charge le message existant dans la zone de texte
        this.editingTimestamp = this.state.newTask.remarque[index].timestamp; // Stocke le timestamp existant
        this.removeRemarque(id); // Supprime le message pour qu'il soit réécrit
      }
    },
    async uploadFile(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Set loading state to true and wait for the DOM to update
      this.isLoading = true;
      await nextTick(); // Ensures the loading spinner is visible

      // Clean the filename to avoid special characters and spaces
      const cleanedFileName = this.cleanFileName(file.name);

      // Create a new file with the cleaned name
      const cleanedFile = new File([file], cleanedFileName, {
        type: file.type,
      });

      // Create FormData to send the file
      const formData = new FormData();
      formData.append("file", cleanedFile);

      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_URL_3000}/todolist/uploadfiles`,
          {
            method: "POST",
            body: formData,
          },
        );

        // Check the response and continue even if the upload fails
        if (!response.ok) {
          console.warn("Upload failed. File may already exist. Continuing...");
        }

        // Generate the file URL after the upload, whether it succeeds or not
        const fileUrl = `https://xkcwddsnkexnxpxdtjkr.supabase.co/storage/v1/object/public/todolist/${cleanedFileName}`; // Generate the storage URL

        // Initialize the URL array if it is empty or not initialized
        if (!Array.isArray(this.state.newTask.url)) {
          this.state.newTask.url = [];
        }

        // Add the file to the URL array with the name and URL
        this.state.newTask.url.push({
          title: cleanedFileName,
          link: fileUrl,
        });

        console.log(
          "File URL generated and added to URL array:",
          this.state.newTask.url,
        );
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        // Reset loading state to false after upload
        this.isLoading = false;
      }
    },
    // Fonction pour nettoyer le nom du fichier
    cleanFileName(fileName) {
      return fileName
        .toLowerCase()
        .normalize("NFD") // Normaliser les caractères Unicode
        .replace(/[\u0300-\u036f]/g, "") // Supprimer les accents
        .replace(/[^a-z0-9.]/g, "_") // Remplacer les caractères non-alphanumériques par un underscore
        .replace(/_+/g, "_") // Remplacer plusieurs underscores par un seul
        .replace(/(^_|_$)/g, ""); // Supprimer les underscores de début et de fin
    },
    handleLinkClick(link) {
      if (link.startsWith("http://") || link.startsWith("https://")) {
        // Open in a new browser window
        window.open(link, "_blank");
      } else {
        // Open in the modal
        this.openModalIframe(link);
      }
    },

    isWebPage(link) {
      // Return true if the link does not end with a file extension
      try {
        const url = new URL(link);
        const pathname = url.pathname;
        const extension = pathname.split(".").pop().toLowerCase();
        const fileExtensions = [
          "pdf",
          "jpg",
          "jpeg",
          "png",
          "gif",
          "doc",
          "docx",
          "xls",
          "xlsx",
          "ppt",
          "pptx",
          "txt",
          "zip",
          "rar",
          "7z",
          "tar",
          "gz",
          "mp3",
          "mp4",
          "avi",
          "mov",
          "mkv",
        ];
        return !fileExtensions.includes(extension);
      } catch (e) {
        // If link is not a valid URL, assume it's not a web page
        return false;
      }
    },

    openModalIframe(link) {
      this.isIframeContent = this.isIframeType(link);
      this.currentLink = link;
      this.isLoading = true;
    },

    closeModalIframe() {
      this.currentLink = null;
      this.isLoading = false;
    },

    isIframeType(link) {
      // Retourne true si le lien n'est pas une image
      return !link.match(/\.(jpg|jpeg|png|gif)$/i);
    },

    onIframeLoad() {
      this.isLoading = false;
    },

    onImageLoad() {
      this.isLoading = false;
    },

    async downloadFile(link, title) {
      this.isLoading = true;
      try {
        const response = await fetch(link, { mode: "cors" });
        if (!response.ok) {
          throw new Error(
            `Erreur lors du téléchargement du fichier : ${response.statusText}`,
          );
        }
        const blob = await response.blob();
        const fileName = title || link.split("/").pop();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        // Nettoyer l'URL créée
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error("Erreur lors du téléchargement du fichier :", error);
        alert(
          "Erreur lors du téléchargement du fichier. Veuillez réessayer plus tard.",
        );
      }
      this.isLoading = false;
    },

    async downloadCurrentFile() {
      this.isLoading = true;
      if (this.currentLink) {
        try {
          const response = await fetch(this.currentLink, { mode: "cors" });
          if (!response.ok) {
            throw new Error(
              `Erreur lors du téléchargement du fichier : ${response.statusText}`,
            );
          }
          const blob = await response.blob();
          const fileName = this.currentLink.split("/").pop();
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();

          // Nettoyer l'URL créée
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } catch (error) {
          console.error("Erreur lors du téléchargement du fichier :", error);
          alert(
            "Erreur lors du téléchargement du fichier. Veuillez réessayer plus tard.",
          );
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
h2 {
  height: fit-content;
}
.url {
  padding-top: 2rem;
}
.urls {
  margin-top: 0.5rem !important;
}
.urls span {
  padding-left: 10px;
  cursor: pointer;
}
.kanban-board {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}

/* .kanban-board > :first-child {
  width: 35%;
}

.kanban-board > :nth-child(2) {
  width: 35%;
}

.kanban-board > :nth-child(3) {
  width: 20%;
  overflow: auto;
  max-height: 50vh;
} */

/* One column */
.columns-1 .kanban-column {
  flex: 0 0 100%;
}

/* Three columns */
.columns-3 .kanban-column.column-1,
.columns-3 .kanban-column.column-2 {
  flex: 0 0 32%;
  max-width: 30%;
}

.columns-3 .kanban-column.column-3 {
  flex: 0 0 28%;
  overflow: auto;
  max-height: 50vh;
}
.kanban-column {
  background-color: #ffffff;
  padding: 1rem;

  border-radius: 8px;
  min-height: 400px; /* Increased minimum height */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.kanban-column-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  width: 100%;
  padding: 0px;
  padding-bottom: 0.5rem;
  border: none;
  border-bottom: 1px solid #ddd;
  text-align: left;
  box-sizing: border-box;
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Distribute space between elements */
  align-items: center; /* Align items vertically */
}

.show-invisible-button {
  /*   font-size: 0.875rem; /* Adjust font size if needed */
  cursor: pointer; /* Change cursor to pointer */
  margin-bottom: 1rem;
}

.task-item {
  /* background-color: #f9f9f9; */
  background-color: white;

  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border-left: 10px solid #ccc; /* Default left border color */
  /* border: 1px solid #ddd; */
  border: 1px solid var(--main-color10);
  text-align: left;
  height: 20px;
  overflow: hidden;
  transition: height 0.3s ease;
  pointer-events: auto;
}

.task-item.expanded {
  height: auto;
}

.task-item p {
  margin: 0;
  margin-bottom: 5px;
}

.empty-placeholder {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  font-style: italic;
}

.description {
  padding-left: 20px;
  border: solid 0.5px var(--main-color);
  border-radius: 5px;

  margin-top: 10px;
  width: 85%;
  line-height: 30px;
  background-color: white;
}
.toogle {
  font-weight: 500;
}

.draggable-list {
  flex-grow: 1;
  min-height: 100px; /* Ensure droppable area */
}

.modal {
  position: fixed;
  /* ou 'absolute' */
  z-index: 1000;
  /* Assurez-vous que cette valeur est supérieure à celle de votre header */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fond semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-footer {
  position: sticky;
  bottom: 0; /* S'assurer que le bouton reste en bas de la modale */
  background-color: white; /* Couleur de fond pour se démarquer */

  border-top: 1px solid #ddd; /* Bordure supérieure pour délimiter */
  text-align: right; /* Aligner le bouton à droite */
  z-index: 100; /* S'assurer que le bouton reste au-dessus du contenu de la modale */
}

.modal-content {
  background-color: white;
  border: 1px solid #888;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
  padding: 20px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
}

.modal-footer button {
  width: 100%;
  background-color: var(--main-color);
  border: var(--main-color);
  padding: 0.5rem;
  height: fit-content;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.modal-container {
  width: 95%;
  height: 95%;
  overflow: auto;
  margin: auto;
}
.modal-container iframe {
  width: 100%;
  height: 100%;
  border: none; /* Supprimez la bordure de l'iframe pour un affichage complet */
}

.close {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}
.modal-header {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.chatgpt label {
  text-align: left;
  width: 15%;
  color: black;
  display: flex;
  align-items: start;
}

.chatgpt select {
  width: fit-content;
  min-width: 150px;
  /* border: 0.5px black solid; */
  border: none;
  padding: 0px 10px;
  height: 30px;
}

.chatgpt {
  display: flex;
  flex-direction: row;
  margin: 1rem 1rem;
  width: 100%;
  flex-wrap: wrap;
}
.chatgpt-container {
  margin: auto;

  min-width: 600px;
  min-height: 500px;
  padding: 2rem 2rem;
  background-color: var(--main-color6);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.chatgpt-container-first-column {
  width: 60%;
}
.chatgpt-container-second-column {
  width: 25%;
}
.chatgpt-container-second-column label {
  width: 35%;
}
.chatgpt6 {
  width: 55%;
  margin-bottom: 0.5rem;
}

.chatgpt2 {
  width: 85%;
}

.chatgpt3 {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
}
.chatgpt5 label {
  width: 35%;
}
.chatgpt4 {
  width: 85%;
  margin: 0;
  padding: 5px 0;
  word-wrap: break-word;
}

.chatgpt input {
  width: 80%;
  /*  border: 0.5px grey solid; */
  border: none;
  padding-left: 10px;
  height: 30px;
}

.text-align-left {
  cursor: pointer;
}

.description-input {
  width: 100%;

  border: none;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow-wrap: break-word;
  white-space: pre-line;
  display: block;
  font-family: inherit;
  font-size: inherit;
}

.remove {
  display: flex;
  align-items: center;
  width: 15%;
  justify-content: center;
  cursor: pointer;
}

.removePerson {
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}

.modal-footer button:hover {
  background-color: var(--main-color2);
  color: var(--main-color);
}
#alwaysVisible {
  width: auto;
}
.add-task-button {
  display: inline;
}
#department {
  text-transform: capitalize;
}

.delete-icon,
.edit-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 5px;
}
.gap {
  display: flex;
  gap: 10px;
}

.delete-icon img,
.edit-icon img {
  width: 20px;
  height: auto;
  padding-left: 20px;
}

.icon {
  display: flex;
  justify-content: right;
  padding-right: 5px;
  /*  width: 100%; */
}

.person_from {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
.task-item.done {
  opacity: 50%;
}

input[type="checkbox"] {
  accent-color: var(--main-color6);
  margin-right: 10px; /* Change this to any color you want */
}

.modal input[type="checkbox"] {
  accent-color: var(--main-color4);
  margin-right: 10px;
}

a {
  color: inherit;
  text-decoration: underline;
}

#dueDate {
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
}

label[for="alwaysVisible"] {
  /* Your CSS styles here */
  align-items: center;
}
label[for="reminderDaysBefore"] {
  /* Your CSS styles here */
  width: 60%;
  align-items: center;
}
#reminderDaysBefore {
  width: 10%;
  margin-left: 1rem;
  text-align: center;
}
.dayContainer {
  font-size: 10px !important;
}

.kanban-last-line {
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;
}

.due-date {
  width: -webkit-fill-available;
  margin-bottom: 0 !important;
}

.due-date-header {
  margin-bottom: 0 !important;
}

.checked-item {
  opacity: 0.5;
}

.filter-toggle-button {
  background-color: var(--main-color);
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}

.all-filters {
  display: flex;
  width: 90%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  margin-top: 10px;
}

.filter-select-container {
  display: flex;
  display: flex;
  width: 350px;
  justify-content: space-around;
  margin: auto;
  align-items: center;
  margin-bottom: 1rem;
}

select {
  width: fit-content;
  min-width: 150px;
  /*  border: 0.5px black solid; */
  border: none;
  padding: 0px 10px;
  height: 30px;
}
.chat-container {
  padding: 10px;
  background-color: white;
  margin-top: 1rem;
  width: 90%;
  max-height: 200px;
  border: 0.5px solid black;
  position: relative;
  overflow-y: auto;
  /* Border radius pour chaque coin */
  border-top-left-radius: 5px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 25px;
}
.chat-container-modal {
  padding: 20px;
  background-color: white;
  margin-top: 1rem;
  width: 90%;
  max-height: 200px;
  border: 0.5px solid black;
  position: relative;
  overflow-y: auto;
  /* Border radius pour chaque coin */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.chat-bubble-modal {
  margin: 5px 0;
  border-radius: 15px;
  max-width: 100%;
  clear: both;
  position: relative;
  text-align: left;
}

.chat-bubble-modal .chat-bubble-header {
  width: 250px;
}
.chat-bubble-header img {
  width: 18px;
}

.chat-bubble-message {
  margin-top: 5px;
  font-size: 1em;
}

.chat-bubble-left {
  background-color: white;
}

.chat-bubble {
  padding: 5px;
  margin: 5px 0;
  border-radius: 15px;
  max-width: 100%;
  clear: both;
  position: relative;
  text-align: left; /* par défaut à gauche */
  width: 75%;
}
.chat-bubble-unread {
  border: 1px solid red; /* Bordure rouge pour les messages non lus */
}

.chat-bubble-right {
  text-align: right; /* Aligne le texte à droite */
  align-self: flex-end; /* Assure l'alignement du conteneur */
  float: right; /* Aligne la bulle elle-même à droite */
  background-color: #e0f7fa; /* Couleur de fond différente pour l'utilisateur actuel */
}

.chat-bubble-left {
  text-align: left; /* Aligne le texte à gauche */
  align-self: flex-start; /* Assure l'alignement du conteneur */
  float: left; /* Aligne la bulle elle-même à gauche */
  background-color: #f0f0f0; /* Couleur de fond pour les autres utilisateurs */
}

.chat-bubble-header {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.new-remarque-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.send-message {
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 500;
  /* color: #333; */
  cursor: pointer;
  box-shadow: 0px 4px 0px #aaa;
  transition: all 0.1s ease-in-out;
  margin: auto;
  margin-top: 1rem;
  width: fit-content;
}

.send-message:hover {
  background-color: #e0e0e0; /* Changer légèrement la couleur de fond au survol */
  box-shadow: 0px 2px 0px #888; /* Réduire l'ombre pour donner un effet d'enfoncement */
  transform: translateY(2px); /* Déplacer le bouton vers le bas */
}

.send-message:active {
  background-color: #d0d0d0; /* Changer encore plus la couleur de fond lorsqu'il est cliqué */
  box-shadow: 0px 1px 0px #666; /* Réduire davantage l'ombre */
  transform: translateY(3px); /* Déplacer encore plus vers le bas */
}

.ajouterURL {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 550px;
}

.underline {
  text-decoration: underline;
}

.uploadFile {
  display: flex;
  text-align: left;
}

.file-input {
  padding-left: 0;
}
.chatgpt-container-first-column .chatgpt {
  margin: 2rem 1rem;
}

pre {
  font-size: inherit;
  font-family: inherit;
  margin: 0px 0px 10px 0px;
}
.checkbox {
  height: auto;
  padding-left: 20px;
}
.liste {
  display: flex;
  margin-bottom: 10px;
}

.alert {
  color: var(--main-color3);
}
/*  .liste-case {
  display: flex;
} */
/* .liste-label {
  margin-bottom: -10px;
} */

.liste-case input[type="checkbox"] {
  position: relative;
  z-index: 10;
}

.task-description {
  width: 95%;
  margin-top: 10px;

  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  background-color: var(--main-color6);
  border-radius: 10px;
}

.kanban-project-title {
  background-color: var(--main-color7);
  width: 95%;
  margin: 1rem auto;
  border: solid 1px var(--main-color7);
}
.drag-handle {
  width: 15px;
  cursor: pointer;
}

element.style {
  color: red;
  font-weight: 800;
}

.align-vertical {
  align-items: center;
}

.align-vertical label {
  width: fit-content;
  padding-right: 10px;
}
.align-vertical input {
  width: 10%;
  height: 15px;
}

.notification {
  color: red;
  font-weight: 900;
}

@media (max-width: 1200px) {
  .kanban-board {
    flex-direction: column;
    padding: 1rem 1rem 1rem 0rem;
  }
  .columns-3 .kanban-column.column-1,
  .columns-3 .kanban-column.column-2 {
    flex: 0 0 32%;
    max-width: 100%;
  }
  .kanban-column {
    margin-bottom: 1rem;
  }

  .kanban-board > :first-child {
    width: 85%;
  }

  .kanban-board > :nth-child(2) {
    width: 85%;
  }

  .kanban-board > :nth-child(3) {
    width: 85%;
    overflow: auto;
    max-height: 50vh;
  }
  .chatgpt-container {
    min-width: 0px;

    flex-direction: column;
    padding: 1rem;
    margin: 0;
  }

  .chatgpt-container-first-column {
    width: 100%;
  }
  .chatgpt label {
    width: fit-content;
    padding-right: 20px;
  }
  .chatgpt {
    flex-direction: row;
    margin: 1.5rem 0rem;
  }

  .chatgpt6 {
    width: fit-content;
  }
  .flatpickr-input,
  .flatpickr-mobile placeholder {
    height: 30px !important;
    width: fit-content;
  }
  .modal-container {
    width: 100%;
    height: 90%;
    padding: 0;
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  .modal-content {
    padding: 0;
    width: 100%;
    min-height: 100vh;
    position: absolute;
    margin: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
    border: 0;
    background-color: var(--main-color6);
  }

  .close {
    top: 5px;
  }
  h2 {
    margin-bottom: 1rem;
    display: none;
  }

  .chatgpt-container-second-column {
    width: 100%;
  }
}
</style>
